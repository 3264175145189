import { LOCALE_DEFAULT } from 'config/config';
import { SlugPageController } from 'server/controller/page/index/slugPageController';
import { SlugPageResource } from 'server/resource/page/slugPageResource';
import type { IGlobalMetaData } from 'types/IGlobalMetaData';
import ClientSlugPage from '../components/PageLevelComponents/ClientSlugPage';
import { Layout } from '@/components/Layout/Layout';

const RootPage = (props: SlugPageResource) => {
  const {
    metaData,
    data,
    breadcrumbsCMSData,
    difficultyCopy,
    searchOverlayData,
    searchItemLabels,
    recipeCopy,
    footerNavData,
    footerImage,
    usercentricsIds,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    locale,
    pathTranslations,
  } = props;

  return (
    <Layout
      themeName={data?.genericPages.at(0)?.theme || ''}
      pathTranslations={pathTranslations}
      locale={locale}
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
    >
      <ClientSlugPage
        globalMetadata={metaData as IGlobalMetaData}
        data={data}
        breadcrumbsCMSData={breadcrumbsCMSData}
        recipeCopy={recipeCopy}
        difficultyCopy={difficultyCopy as Record<string, string>}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData}
      />
    </Layout>
  );
};

type GetServerSidePropsParams = {
  locale: string;
  params: {
    slug: string;
  };
  defaultLocale: string;
  resolvedUrl: string;
};

export const getServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
}: GetServerSidePropsParams) => {
  return SlugPageController.getCachedSlugPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: params.slug,
  });
};

export default RootPage;
